<template>
 <div>
      <section id="soluciones" class="values"  v-if="!(language.indexOf('es') === -1)">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2>Soluciones a Medida</h2>
            <p>Servicios de TI de tu negocio</p>
          </header>
          <div class="row">
            <div class="col-lg-4">
              <router-link :to="{ name: 'IT Services', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="200" >
                <img src="assets/img/portfolio/dr.webp" class="img-fluid" alt="">
                <h3>IT Consult</h3>
                <p style="color:#2c3e50;">Servicios profesionales Integrales en el área de Tecnología y Seguridad de la Información</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Vmware', params: { userId: 123 }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="400">
                <img src="assets/img/portfolio/it/vmware.webp" class="img-fluid" alt="">
                <h3>Vmware Solutions</h3>
                <p style="color:#2c3e50;">La mejor tecnología de virtualización del mercado a tu alcance.</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'IT Services', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/it/datacenter.webp" class="img-fluid" alt="">
                <h3>Datacenters</h3>
                <p style="color:#2c3e50;">Diseño, renovación de Datacenters. Instalaciones y obras llave en mano de la mano de nuestros especialistas</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'IT Services', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/dr/hardware.webp" class="img-fluid" alt="">
                <h3>Hardware & Software</h3>
                <p style="color:#2c3e50;">Soluciones integrales a medida para tu organización. Renová tu Infraestructura productiva con las mejores marcas del Mercado.</p>
              </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
           <section id="soluciones" class="values"  v-if="(language.indexOf('es') === -1)">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2>TAILOR-MADE SOLUTIONS</h2>
            <p>IT services for your business</p>
          </header>
          <div class="row">
            <div class="col-lg-4">
              <router-link :to="{ name: 'IT ServicesEn', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="200" >
                <img src="assets/img/portfolio/dr.webp" class="img-fluid" alt="">
                <h3>IT Consult</h3>
                <p style="color:#2c3e50;">.</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'VmwareEn', params: { userId: 123 }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="400">
                <img src="assets/img/portfolio/it/vmware.webp" class="img-fluid" alt="">
                <h3>Vmware Solutions</h3>
                <p style="color:#2c3e50;">..</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'IT ServicesEn', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/it/datacenter.webp" class="img-fluid" alt="">
                <h3>Datacenters</h3>
                <p style="color:#2c3e50;"> . </p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'IT ServicesEn', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/dr/hardware.webp" class="img-fluid" alt="">
                <h3>Hardware & Software</h3>
                <p style="color:#2c3e50;"> . </p>
              </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
 </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String,
    language: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.col-lg-5 {
    flex: 0 0 50%;
    max-width: 50%;
}
.img-fluid {
    max-width: 100%;
    height: 236px;
    width: auto;
}

</style>
