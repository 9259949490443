<template>
    <div>
      <MainBannerIT :language = "dominioactual" />
      <SolucionesIT :language = "dominioactual" />
  </div>
</template>

<script>
import MainBannerIT from './MainBannerIT.vue'
import SolucionesIT from './SolucionesIT.vue'
export default {
  components: {
    MainBannerIT, SolucionesIT
  },
  data () {
    return {
      dominioactual: navigator.language
    }
  },
  created () {
    this.track()
  },
  methods: {
    track () {
      this.$gtag.pageview({ page_path: '/solutions/itservices' })
    }
  }
}
</script>

<style>

</style>
